<template>
    <div>
        <div v-if="!showSkeleton" class="survey">
            <div v-if='active === 0'>
                <div v-html="detail.survey_descr"></div>
                <div>
                    <van-image v-if="detail.survey_image" height="200" :src="detail.survey_image" />
                </div>
                <div style="background:#fff;padding-bottom:10px">
                    <van-form @submit="save" :colon='true' :scroll-to-error="true">
                        <div v-for="(item,index) in questions" :key='item.survey_question_id'>
                            <van-field
                                v-if="item.survey_question_type == 'text'"
                                v-model="survey[item.valueName]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="请输入"
                                @input="handleInput"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                clearable
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'credential_number'"
                                v-model="survey[item.valueName]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="请输入"
                                @input="handleInputCredentialNumber"
                                class="survey-van-field-boeder"
                                clearable
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'email'"
                                v-model="survey[item.valueName]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="请输入"
                                @input="handleInput"
                                clearable
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'mobile'"
                                v-model="survey[item.valueName]"
                                type="tel"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="请输入"
                                @input="handleInput"
                                clearable
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ validator:validatorPhone,required: item.survey_question_can_skip == 1, message: '请输入正确手机号' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'number'"
                                v-model="survey[item.valueName]"
                                type="number"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="请输入"
                                @input="handleInput"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'checkbox'"
                                :name="item.survey_name_id"
                                :label="item.label"
                                class="survey-van-radio-box"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]">
                                <template #input>
                                    <van-checkbox-group checked-color="#00DE93" v-model="survey.checkedList" direction="horizontal">
                                        <van-checkbox @click="handleCheckbox(it)" :class="it.checkB?'survey-van-radio-boederCheck':'survey-van-radio-boeder'"  v-for="it in item.options" :key='it.survey_answer_id' :name="it.survey_answer_id" shape="square">{{it.survey_answer_name}}</van-checkbox>
                                    </van-checkbox-group>
                                </template>
                            </van-field>


                            <van-field
                                v-if="item.survey_question_type == 'radio' || item.survey_question_type == 'gender'"
                                :name="item.survey_name_id"
                                :label="item.label"
                                class="survey-van-radio-box"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]">
                                <template #input>
                                    <van-radio-group v-model="survey[item.valueName]" @change="e=>handleInput(e,item)" direction="horizontal">
                                        <van-radio :class="survey[item.valueName] == it.survey_answer_id?'survey-van-radio-boederCheck':'survey-van-radio-boeder'" v-for="it in item.options" :key='it.survey_answer_id' :name="it.survey_answer_id" >{{it.survey_answer_name}}</van-radio>
                                    </van-radio-group>
                                </template>
                            </van-field>


                            <van-field
                                v-if="item.survey_question_type == 'textarea'"
                                v-model="survey[item.valueName]"
                                rows="2"
                                autosize
                                :label="item.label"
                                :name="item.survey_name_id"
                                type="textarea"
                                maxlength="1000"
                                placeholder="请输入留言"
                                show-word-limit
                                @input="handleInput"
                                clearable
                                class="survey-van-textarea-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请输入' }]"
                            />


                            <van-field
                                v-if="item.survey_question_type == 'dropdown'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showDropdown(index)"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'ajax_dropdown'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showAjaxDropdown(index)"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'ajax_sub_dropdown'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showAjaxSubDropdown(index)"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'studio'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showStudio = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'teacher'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showTeacher = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'map_address'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showMapAddress = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />

                            <van-field
                                v-if="item.survey_question_type == 'course_consultant'"
                                readonly
                                clickable
                                :name="item.survey_name_id"
                                :label="item.label"
                                :value="survey[item.valueName]"
                                placeholder="点击选择"
                                @click="showCourseConsultant = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />
                            

                            <van-field
                                v-if="item.survey_question_type == 'date'"
                                readonly
                                clickable
                                :value="survey[item.valueName]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="点击选择"
                                @click="showPickers = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />


                            <van-field
                                v-if="item.survey_question_type == 'datetime'"
                                readonly
                                clickable
                                :value="survey[item.valueName]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                placeholder="点击选择"
                                @click="showPickert = true"
                                class="survey-van-field-boeder"
                                :required='item.survey_question_can_skip == 1'
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                            />


                            <van-field 
                                v-if="item.survey_question_type == 'image'"
                                :rules="[{ required: item.survey_question_can_skip == 1, message: '请选择' }]"
                                :name="item.survey_name_id"
                                :label="item.label"
                                :required='item.survey_question_can_skip == 1'
                                >
                                <template #input>
                                    <van-uploader :after-read="e=>afterRead(e,item.imgList)" :before-delete='(e,b)=>beforeDelete(e,b,item.imgList)' multiple :max-count="9" v-model="survey[item.valueName]" />
                                </template>
                            </van-field>
                            
                            <div class="signature-box" v-if="item.survey_question_type == 'signature'" >
                                <div class="signature-box-title"><span v-if="item.survey_question_can_skip == 1">*</span>{{item.label}}:</div>
                                <div style="background:rgb(250,250,250)">
                                    <VueSignaturePad 
                                        id="signature"
                                        height="200px" 
                                        :customStyle="{ border:'1px dashed #BFBFBF'}"
                                        :options="SignatureOptions"
                                        ref="signaturePad" />
                                </div>
                                <van-row gutter="20">
                                    <van-col span="24"><div class="signature-btn" @click="undo" type="default">清除</div></van-col>
                                    <!-- <van-col span="12"><div class="signature-btn" @click="save" type="default">保存</div></van-col> -->
                                </van-row>
                            </div>
                            
                            <div class="location" v-if="item.survey_question_type == 'location'" >
                                <div class="location-title"><span v-if="item.survey_question_can_skip == 1">*</span>{{item.label}}:</div>
                                <div v-if='!isMap' @click="getLocation" class="location-wrap">
                                    <van-icon color="#00DE93" size="25" name="location-o" />
                                    <span style="color:#00DE93">点击获取地理位置</span>
                                </div>
                                <div class="location-map" v-if='isMap' >
                                    <div class="map-wrap" :id="`map`"></div>
                                    <div @click="getLocation" class="location-icon"><van-icon size="20" name="aim" /></div>
                                </div>
                                <div v-if='isMap' class="location-address">{{newValue}}</div>
                            </div>

                            <van-popup v-model="showStudio" position="bottom" v-if="item.survey_question_type == 'studio'">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item.options"
                                    @confirm="e=>studioConfirm(e,item.valueName,item.select)"
                                    @cancel="showStudio = false"
                                />
                            </van-popup>

                            <van-popup v-model="showTeacher" position="bottom" v-if="item.survey_question_type == 'teacher'">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item.options"
                                    @confirm="e=>teacherConfirm(e,item.valueName,item.select)"
                                    @cancel="showTeacher = false"
                                />
                            </van-popup>

                            <van-popup v-model="showMapAddress" position="bottom" v-if="item.survey_question_type == 'map_address'">
                                <van-area
                                    :area-list="areaList"
                                    @confirm="e=>mapAddressConfirm(e,item.valueName,item.select)"
                                    @cancel="showMapAddress = false"
                                />
                            </van-popup>

                            <van-popup v-model="showCourseConsultant" position="bottom" v-if="item.survey_question_type == 'course_consultant'">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item.options"
                                    @confirm="e=>courseConsultantConfirm(e,item.valueName,item.select)"
                                    @cancel="showCourseConsultant = false"
                                />
                            </van-popup>

                            <van-popup v-model="showPicker" position="bottom" v-if="item.survey_question_type == 'dropdown' && activeIndex == index">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item[item.optionsName]"
                                    @confirm="e=>onConfirm(e,item.valueName,item.select,index,item)"
                                    @cancel="showPicker = false"
                                />
                            </van-popup>
                            <van-popup v-model="showAjaxPicker" position="bottom" v-if="item.survey_question_type == 'ajax_dropdown' && activeIndex == index">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item[item.optionsName]"
                                    @confirm="e=>onConfirmAjaxDropdown(e,item.valueName,item.select,index)"
                                    @cancel="showAjaxPicker = false"
                                />
                            </van-popup>
                            <van-popup v-model="showAjaxSubPicker" position="bottom" v-if="item.survey_question_type == 'ajax_sub_dropdown' && activeIndex == index">
                                <van-picker
                                    show-toolbar
                                    value-key='label'
                                    :columns="item[item.optionsName]"
                                    @confirm="e=>onConfirmAjaxSubDropdown(e,item.valueName,item.select,index)"
                                    @cancel="showAjaxSubPicker = false"
                                />
                            </van-popup>
                            <van-popup v-model="showPickers" position="bottom" v-if="item.survey_question_type == 'date'">
                                <van-datetime-picker
                                    type="date"
                                    :min-date="minDate"
                                    :max-date="maxDate"
                                    v-model="survey[item.date]"
                                    @confirm="e=>onConfirmDate(e,item.valueName)"
                                    @cancel="showPickers = false"
                                />
                            </van-popup>
                            <van-popup v-model="showPickert" position="bottom" v-if="item.survey_question_type == 'datetime'">
                            <van-datetime-picker
                                    :min-date="minDate"
                                    :max-date="maxDate"
                                    v-model="survey[item.date]"
                                    @confirm="e=>onConfirmDateTime(e,item.valueName)"
                                    @cancel="showPickert = false"
                                />
                            </van-popup>
                        </div>
                        <div style="margin: 16px;">
                            <van-checkbox v-model="isCheck" checked-color="#07c160">
                                <span>我已阅读并同意</span>
                                <span @click.stop="toAgreement" style="color:#00DE93">《学员协议》</span>
                            </van-checkbox>
                        </div>
                        <div style="margin: 16px;">
                            <van-button :disabled="!isCheck" round block type="info" native-type="submit">
                            提交
                            </van-button>
                        </div>
                    </van-form>
                </div>
            </div>
            <loadings :loading='loading' />
        </div>
        <van-skeleton v-if="showSkeleton"  title :row="7" />
        <van-popup v-model="isAgreement" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto'}">
            <div class="agreement ql-editor" v-html="agreementHtml"></div>
        </van-popup>
    </div>
</template>

<script>
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import areaList from '@/common/huidongArea'
    import upload from '@/utils/upload'
    import Loadings from '@/components/loading'
    import dayjs from 'dayjs'
    import { wxPay } from '@/common/wx'
    import { getBirthday } from '@/common/util'
    import { lazyAMapApiLoaderInstance } from 'vue-amap';
    import { Checkbox, CheckboxGroup, Form, Field, RadioGroup, Radio, Picker, Popup, DatetimePicker, Uploader ,Overlay, Step, Steps, Skeleton, Area} from 'vant'

    export default {
        name:"huidongApply",
        components: {
            [Field.name]: Field,
            [Form.name]: Form,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Overlay.name]: Overlay,
            [Step.name]: Step,
            [Steps.name]: Steps,
            [Skeleton.name]: Skeleton,
            [Area.name]: Area,
            loadings:Loadings
        },
        data() {
            return {
                detail: {},
                questions:[],
                showPicker:false,
                showPickers:false,
                showPickert:false,
                showAjaxPicker:false,
                showAjaxSubPicker:false,
                showStudio:false,
                showTeacher:false,
                showMapAddress:false,
                showCourseConsultant:false,
                isAgreement:false,
                survey:{},
                SignatureOptions:{
                    penColor: "#000",
                },
                survey_id:'',
                signaturePad:'',
                survey_msg:{},
                recruit_id:'',
                minDate: new Date(1970, 1, 1),
                maxDate: new Date(),
                activeIndex:-1,
                newValue:'',
                isMap:false,
                loading:false,
                isCheck:false,
                active:0,
                apply_id:null,
                applyInfo:{},
                showSkeleton:false,
                areaList: areaList,
                signaturePadBase64:'',
                agreementHtml: '',
                is_new_student: 0,
                student_list: []
            }
        },
        async created () {
            this.apply_id = this.$route.query.apply_id
            await this.getInfo()
            await this.getDetail()
        },
        methods: {
            async getDetail() {
                this.loading = true
                let that = this
                await this.$api.crm_contract_form({apply_id:this.apply_id})
                    .then( async res => {
                        this.detail = res.data.survey
                        this.student_list = res.data.survey.student_list
                        await res.data.survey.questions.forEach((item,index)=>{
                            let valueName = item.survey_question_type + index
                            let img = 'imgList' + index
                            let select = 'selectValue' + index
                            let date = 'dateValue' + index
                            let optionsList = 'options' + index
                            item.valueName = valueName
                            if(item.survey_question_type == 'checkbox' || item.survey_question_type == 'image' ){
                                item.imgList = img
                                that.survey[valueName] = []
                                that.survey[img] = []
                                if(item.survey_question_type == 'checkbox'){
                                    item.options.forEach(it=>{
                                        it.checkB = false
                                    })
                                }
                            }else if(item.survey_question_type == 'dropdown' || item.survey_question_type == 'studio' || item.survey_question_type == 'teacher' || item.survey_question_type == 'course_consultant' || item.survey_question_type == 'map_address' || item.survey_question_type == 'ajax_dropdown' || item.survey_question_type == 'ajax_sub_dropdown'){
                                item.select = select
                                item[optionsList] = item.options
                                item.optionsName = optionsList
                                that.survey[select] = ''
                                that.survey[valueName] = ''
                            }else if(item.survey_question_type == 'date' || item.survey_question_type == 'datetime'){
                                item.date = date
                                that.survey[date] = new Date()
                                that.survey[valueName] = dayjs().format('YYYY-MM-DD')
                            }else if(item.survey_question_type == 'mobile'){
                                that.survey[valueName] = item.survey_question_value
                            }else{
                                that.survey[valueName] = ''
                            }
                            item.survey_name_id = item.survey_question_id.toString()
                            if(item.survey_question_descr){
                                item.label = `${item.survey_question_name}(${item.survey_question_descr})`
                            }else{
                                item.label = `${item.survey_question_name}`
                            }
                        })
                        this.questions = res.data.survey.questions
                        let huidongApplyValue = localStorage.getItem('huidongApplyValue')
                        if(huidongApplyValue && huidongApplyValue !=0){
                            this.survey = JSON.parse(huidongApplyValue)
                        }
                        if(this.survey.is_new_student){
                            this.is_new_student = 1
                        }
                        this.loading = false
                        this.showSkeleton = false
                    })
                    .catch(err => {
                        this.loading = false
                        console.error(err)
                    })
            },
            async getInfo(){
                let that = this
                this.loading = true
                this.showSkeleton = true
                await this.$api.crm_contract_detail({apply_id:this.apply_id})
                .then(res=>{
                    this.applyInfo = res.data
                    this.getAagreement(res.data.studio.studio_id)
                    if(res.data.apply_status === 0){
                        this.active = 0
                    }else if(res.data.apply_status === 1){
                       this.$router.push({path:'/contractPayment',query:{apply_id:this.apply_id,appid:localStorage.getItem('appid'),redirects:1}})
                    }
                    this.loading = false
                })
            },
            async getAagreement(id){
                await this.$api.premium_agreement({studio_id:id})
                .then(res=>{
                    this.agreementHtml = res.data.agreement
                })
            },
            showDropdown(index){
                this.activeIndex = index
                this.showPicker = true
            },
            showAjaxDropdown(index){
                this.activeIndex = index
                this.showAjaxPicker = true
            },
            showAjaxSubDropdown(index){
                this.activeIndex = index
                this.showAjaxSubPicker = true
            },
            onSubmit(value){
                let SurveyUserAnswer = {}
                try{
                    this.questions.forEach(item=>{
                        if(item.survey_question_type == 'image'){
                            SurveyUserAnswer[item.survey_name_id] = this.survey[item.imgList]
                        }else if(item.survey_question_type == 'signature'){
                            if(item.survey_question_can_skip == 1 && !this.signaturePad){
                                this.$refs.signaturePad[0].openSignaturePad()
                                this.$toast({ message: '请签名~', duration: 1500 })
                                throw new Error('请签名~')
                            }
                            if(this.signaturePad){
                                SurveyUserAnswer[item.survey_name_id] = this.signaturePad
                            }else{
                                SurveyUserAnswer[item.survey_name_id] = ''
                            }
                        }else if(item.survey_question_type == 'dropdown' || item.survey_question_type == 'ajax_dropdown' || item.survey_question_type == 'ajax_sub_dropdown'){
                            SurveyUserAnswer[item.survey_name_id] = this.survey[item.select]
                        }else if(item.survey_question_type == 'checkbox'){
                            let arr = {}
                            item.options.forEach(it=>{
                                value[item.survey_name_id].forEach(i=>{
                                    if(it.value  == i){
                                        it.ckeck = 1
                                    }
                                })
                            })
                            item.options.forEach(it=>{
                                if(it.ckeck){
                                    arr[it.value] = '1'
                                }else{
                                    arr[it.value] = '0'
                                }
                            })
                            SurveyUserAnswer[item.survey_name_id] = arr
                        }else if(item.survey_question_type == 'radio' || item.survey_question_type == 'gender'){
                            let arr = ''
                            item.options.forEach(it=>{
                                if(it.value == this.survey[item.valueName]){
                                    arr = it.label
                                }
                            })
                            SurveyUserAnswer[item.survey_name_id] = value[item.survey_name_id]
                        }else if(item.survey_question_type == 'studio' || item.survey_question_type == 'teacher' || item.survey_question_type == 'course_consultant'){
                            SurveyUserAnswer[item.survey_name_id] = this.survey[item.select]
                        }else if(item.survey_question_type == 'date' || item.survey_question_type == 'datetime'){
                            SurveyUserAnswer[item.survey_name_id] = value[item.survey_name_id]
                        }else if(item.survey_question_type == 'location'){
                            if(item.survey_question_can_skip == 1 && !this.newValue){
                                this.$toast({ message: '请获取当前地址~', duration: 1500 })
                                throw new Error('请获取当前地址~')
                            }
                            SurveyUserAnswer[item.survey_name_id] = this.newValue
                        }else if(item.survey_question_type == 'map_address'){
                            SurveyUserAnswer['province'] = this.survey[item.select][0]['name']
                            SurveyUserAnswer['city'] = this.survey[item.select][1]['name']
                            SurveyUserAnswer['district'] = this.survey[item.select][2]['name']
                        }else{
                            SurveyUserAnswer[item.survey_name_id] = value[item.survey_name_id]
                        }
                    })
                } catch (e){
                    this.$toast({ message: e, duration: 1500 })
                }
                SurveyUserAnswer.is_new_student = this.is_new_student
                this.$api.crm_contract_apply({apply:SurveyUserAnswer,apply_id:this.apply_id})
                    .then(res => {
                        this.$toast({ message: '提交成功~', duration: 1500 })
                        this.apply_id = res.data.apply_id
                        if(this.applyInfo.screen_shot.length > 0 || this.applyInfo.apply_status === 2){
                            this.$router.push({path:'/paymentSuccess',query:{apply_id:res.data.apply_id}})
                        }else{
                            this.$router.push({path:'/contractPayment',query:{apply_id:res.data.apply_id,appid:localStorage.getItem('appid'),redirects:1}})
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.loading = false
                        this.$toast({ message: err, duration: 1500 })
                    })
            },
            onConfirm(value,name,select,index,item) {
                this.survey[name] = value.label
                this.survey[select] = value.value
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                this.showPicker = false;
            },
            onConfirmAjaxDropdown(value,name,select,index) {
                this.questions.forEach(item=>{
                    if(item.survey_question_type === 'ajax_sub_dropdown'){
                        this.survey[item.valueName] = ''
                        this.survey[item.select] = ''
                    }
                })
                this.getFeeCoupons(value.value)
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showAjaxPicker = false;
            },
            onConfirmAjaxSubDropdown(value,name,select,index) {
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showAjaxSubPicker = false;
            },
            studioConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showStudio = false;
            },
            teacherConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showTeacher = false;
            },
            mapAddressConfirm(value,name,select){
                this.survey[name] = `${value[0].name}${value[1].name}${value[2].name}`;
                this.survey[select] = value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showMapAddress = false;
            },
            courseConsultantConfirm(value,name,select){
                this.survey[name] = value.label;
                this.survey[select] = value.value;
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showCourseConsultant = false;
            },
            onConfirmDate(time,name){
                this.survey[name] = dayjs(time).format('YYYY-MM-DD');
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showPickers = false;
            },
            onConfirmDateTime(time,name){
                this.survey[name] = dayjs(time).format('YYYY-MM-DD HH:mm');
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey));
                this.showPickert = false;
            },
            afterRead(file,name) {
                this.$forceUpdate()
                let that = this;
                if(!file.length){
                    upload({ file: file.file })
                    .then(res => {
                        if(that.survey[name]){
                            that.survey[name].push(res.fileName)
                            localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                        }else{
                            that.survey[name] = []
                            that.survey[name].push(res.fileName)
                            localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                        }
                        
                    })
                    .catch(err => {
                        console.error(err)
                    })
                }else{
                    file.forEach(it=>{
                        upload({ file: it.file })
                            .then(res => {
                                if(that.survey[name]){
                                    that.survey[name].push(res.fileName)
                                }else{
                                    that.survey[name] = []
                                    that.survey[name].push(res.fileName)
                                }
                                
                            })
                            .catch(err => {
                                console.error(err)
                            })
                    })
                }
            },
            beforeDelete(file,detail,name){
                this.survey[name].splice(detail.index,1)
                this.$forceUpdate()
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                return true
            },
            undo() {
                this.$refs.signaturePad[0].openSignaturePad()
                this.$refs.signaturePad[0].clearSignature()
            },
            save(value) {
                this.loading = true
                let that = this
                if(this.$refs.signaturePad){
                    const { isEmpty, data } = this.$refs.signaturePad[0].saveSignature()
                    if(!isEmpty){
                    // 兼容ios11.4之前版本
                    let blob = this.dataURLtoBlob(data)
                    let file = this.blobToFile(blob, 'file')
                    if(file.size == 0){
                        this.$toast({ message: '您的签名有问题,请重新签名~', duration: 1500 })
                        this.$refs.signaturePad[0].clearSignature()
                        this.loading = false
                        return false
                    }
                    this.$refs.signaturePad[0].lockSignaturePad()
                    upload({ file: file})
                        .then(res => {
                            that.signaturePad = res.fileName
                            that.onSubmit(value)
                        })
                        .catch(err=>{
                            that.loading = false
                            that.$refs.signaturePad[0].openSignaturePad()
                            that.$toast({ message: '您的签名上传失败~', duration: 1500 })
                        })
                    }else{
                        this.loading = false
                        this.$toast({ message: '请签名~', duration: 1500 })
                    }
                }else{
                    this.onSubmit(value)
                }
            },
            //将base64转换为blob
            dataURLtoBlob(dataurl) { 
                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new Blob([u8arr], { type: mime });
            },
            //将blob转换为file
            blobToFile(theBlob, fileName){
                theBlob.lastModifiedDate = new Date();
                theBlob.name = fileName;
                return theBlob;
            },
            handleInput(e,item){
                if(item && item.survey_name_id == 'student_id'){
                    if(e !=0){
                        this.questions.forEach(item=>{
                            if(item.survey_question_id === 'nickname'){
                                this.survey[item.valueName] = this.student_list[e]
                            }
                        })
                        this.is_new_student = 0
                    }else{
                        this.is_new_student = 1
                        this.questions.forEach(item=>{
                            if(item.survey_question_id === 'nickname'){
                                this.survey[item.valueName] = ''
                            }
                        })
                    }
                    this.survey.is_new_student = this.is_new_student
                }
                this.$forceUpdate()
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
            },
            handleInputCredentialNumber(index){
                this.$forceUpdate()
                localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                let time = getBirthday(index)
                if(time){
                    let valueName = ''
                    this.questions.forEach(item=>{
                        if(item.survey_question_id == 'birthday'){
                            valueName = item.valueName
                        }
                    })
                    this.survey[valueName] = dayjs(time).format('YYYY-MM-DD')
                    localStorage.setItem('huidongApplyValue',JSON.stringify(this.survey))
                }

            },
            paymentRecruit(recruit_id, couponId = '') {
                this.$toast.loading({
                    mask: true,
                    message: '支付中...',
                    duration: 0
                })
                const params = {
                    recruit_id: recruit_id
                }
                couponId && (params.coupon_id = couponId)
                this.$api.payment_recruit(params)
                    .then(res => {
                    const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
                    wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
                        .then(res => {
                        this.$toast.success('支付成功')
                        setTimeout(() => {
                            this.$router.push({path:'/paymentSuccess'})
                        }, 1500)
                        })
                        .catch(err => {
                        this.$toast.fail('支付失败')
                        setTimeout(() => {
                            
                        }, 1500)
                        console.error(err)
                        })
                    })
                    .catch(err => {
                    console.error(err)
                    })
            },
            handleCheckbox(e){
                if(e.checkB){
                    e.checkB = false
                }else{
                    e.checkB = true
                }
                this.$forceUpdate()
            },
            getLocation(){
                this.isMap = true
                lazyAMapApiLoaderInstance.load().then(() => {
                    const geolocation = new AMap.Geolocation({
                        enableHighAccuracy: true,
                        timeout: 100,
                        zoomToAccuracy: true,     
                        buttonPosition: 'RB'
                    })
                    geolocation.getCurrentPosition()
                    AMap.event.addListener(geolocation, 'complete', (a)=>{
                        let pos = [a.position.lng,a.position.lat]
                        this.newValue = a.formattedAddress
                        const map = new AMap.Map(`map`, {
                            resizeEnable: true,
                            center:pos
                        })
                        const marker = new AMap.Marker({
                            position: pos,
                            cursor: 'move',
                        });
                        map.setZoom(16)
                        marker.setMap(map)
                    })
                })
            },
            toAgreement(){
                this.isAgreement = true
            },
            validatorEmail(val) {
                var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
                return reg.test(val)
            },
            validatorPhone(val) {
                // let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
                let reg = /^1[0-9]{10}$/;
                return reg.test(val)
            },
            async getFeeCoupons(id){
                await this.$api.crm_fee_coupons({fee_id:id})
                .then(res=>{
                    this.questions.forEach(item=>{
                        if(item.survey_question_type === 'ajax_sub_dropdown'){
                            item[item.optionsName] = res.data.options
                        }
                    })
                    this.$forceUpdate()
                })
            },
            handleChangeStep(){
                this.active = 0
            }
        },
    }
</script>

<style lang="less">
    .survey{
        padding: 0 10px;
        background: #fff;
        &-name{
            padding:15px 0;
            font-size:16px;
            font-weight: bold;
        }
        .van-popup--bottom{
            z-index: 2200 !important;
        }
        .signature-box{
            background: #fff;
            padding: 0.2rem 0.32rem;
            .signature-box-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            .signature-btn{
                color: #323233;
                background-color: #fff;
                border: 1px solid #ebedf0;
                text-align: center;
                padding: 10px 0;
                border-radius: 2px;
                margin-top: 10px;
            }
        }
        #signature {
            width: auto !important;
            border: double 3px transparent;
            border-radius: 5px;
            // background-image: linear-gradient(white, white),
            //     radial-gradient(circle at top left, #4bc5e8, #9f6274);
            background-origin: border-box;
            background-clip: content-box, border-box;
        }
        .van-cell{
            display: block;
        }
        .van-cell:not(:last-child)::after{
            border-bottom:none
        }
        .van-field__label{
            width: 100%;
        }
        .van-button--info{
            background-color: #00DE93;
            border:1px solid #00DE93;
        }
        .van-radio--horizontal{
            margin-right: 0px;
        }
        .report-btn{
            width: 100%;
            height: 50px;
            background-color: #00DE93;
            position: fixed;
            bottom: 0;
            left: 0;
            text-align: center;
            line-height: 50px;
            color: #fff;
        }
        .survey-van-field-boeder{
            .van-field__body{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-textarea-boeder{
            .van-cell__value{
                border: 1px solid #d9d9d9;
                padding: 5px;
                border-radius: 2px;
            }
        }
        .survey-van-radio-box{
            .van-radio-group--horizontal,.van-checkbox-group--horizontal{
                display: block;
                width: 100%;
                .survey-van-radio-boeder{
                    border: 1px solid #d9d9d9;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
                .survey-van-radio-boederCheck{
                    border: 1px solid #00DE93;
                    padding: 5px;
                    border-radius: 2px;
                    margin-bottom: 5px;
                }
            }
        }
        .location{
            background: #fff;
            padding: 0.2rem 0.32rem;
            &-title{
                margin-bottom: 5px;
                position: relative;
                span{
                    position: absolute;
                    left: -0.16rem;
                    color: #ee0a24;
                    font-size: 14px;
                }
            }
            &-wrap{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #d9d9d9;
                height: 100px;
            }
            &-map{
                border: 1px solid #d9d9d9;
                position: relative;
            }
            &-inp{
                border-top: 1px solid #d9d9d9;
            }
            &-address{
                padding: 5px;
                border-left: 1px solid #d9d9d9;
                border-right: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
            }
            &-icon{
                position: absolute;
                right: 15px;
                bottom: 15px;
                z-index: 100;
            }
            .map-wrap {
                height: 150px;
            }
        }
        .agreement{
            padding: 0 10px;
            padding-top: 20px;
            padding-bottom: 50px;
        }
        .ql-editor{
            line-height: 1.7;
        }
    }
</style>